<template>
  <div class="uploadLonely">
    <!-- 
            单图上传
            action：上传地址
            txt：按钮显示文字
         -->
    <el-upload class="upload-demo" :action="actionUrl()" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload" :file-list="fileList" :limit="1" :on-preview="handlePreview"
      :on-exceed="handleExceed" :on-remove="handleRemove">
      <!-- 正常状态 -->
      <el-button size="small" plain v-if="!danger">{{form}}</el-button>
      <span class="tip" v-if="!danger"><i class="el-icon-warning"></i>{{tip}}</span>

      <!-- 错误状态 -->
      <!-- 
                danger 用于必填状态下，切换样式
             -->
      <el-button type="danger" size="small" plain v-if="danger">{{form}}</el-button>
      <span class="danger" v-if="danger"><i class="el-icon-warning"></i>{{tip}}</span>
    </el-upload>

    <el-dialog :visible.sync="dialogVisible" class="upload-imgs" :modal-append-to-body='false'>
      <img class="dialog-img" :src="dialogImageUrl" alt="" style="z-index:3000">
    </el-dialog>

  </div>
</template>

<script>
export default {
  data() {
    return {
      imageUrl: "", //上传成功
      imageUrlOne: [], //上传成功时保存图片  提交
      dialogVisible: false,
      dialogImageUrl: "",
    };
  },
  // 接受父组件的值
  props: {
    form: String, //按钮名字
    uploadUrl: "", //文件上传地址
    fileList: Array,
    tip: String, //提示信息
    danger: Boolean, //是否必填
    uploadSize: Number, //上传的文件大小
    uoloadSingleType: "", //上传的类型   music音乐  img图片
    uoloadSingleStatus: "", //控件的状态   add添加    edit修改
    uploafType: "", //新增/编辑医生针对上传的每一张传不同类型
  },
  created() {
    // console.log(this.uoloadSingleStatus);
    // console.log(this.fileList);
    // console.log(this.uploadSize);
    // console.log(this.uoloadSingleType);
  },
  mounted: function () {},
  methods: {
    actionUrl() {
      return this.uploadUrl;
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
      var reImg = res.data;
      if (res.code == 1) {
        if (this.uploafType == undefined) {
          this.$emit("childByValue", res.data, this.uploadUrl);
        } else {
          this.$emit("childByValue", res.data, this.uploafType);
        }
      }
    },
    beforeAvatarUpload(file) {
      //上传大小限制
      var fileSize;
      if (this.uploadSize == 1) {
        fileSize = 1;
      } else if (this.uploadSize == 2) {
        fileSize = 2;
      } else {
        fileSize = 20;
      }
      const isLt2M = file.size / 1024 / 1024 < fileSize;
      if (!isLt2M) {
        this.$message({
          message: "上传文件大小不能超过 " + fileSize + "MB!",
          type: "warning",
        });
      }
      return isLt2M;
    },
    handlePreview(file) {
      if (this.uoloadSingleStatus == "add") {
        this.dialogImageUrl = file.response.data;
      } else {
        this.dialogImageUrl = file.url;
      }
      this.dialogVisible = true;
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 1  个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    handleRemove(file, fileList) {
      //移除
      if (fileList.length == 0) {
        this.$emit("childByValue", "", this.uploadUrl);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.tip,
.danger {
  color: #dddddd;
  margin-left: 15px;
  .el-icon-warning:before {
    color: #dddddd;
    margin-right: 5px;
  }
}
.danger {
  color: #ff4d52;
  .el-icon-warning:before {
    color: #ff4d52;
  }
}
</style>
