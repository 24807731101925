<template>
    <div>
         <!-- 操作性按钮弹框 start-->
        <el-dialog
            title="系统提示"
            :visible.sync="showHide"
            width="480px"
            top="20%"
            :before-close="handleClose"
            >
            <div style="padding:60px 81px" class="operationBtn clearfix" >
                 <div class="fl operationBtn-l">
                     <img src="../assets/image/warning.png">
                 </div>
                 <div class="fl operationBtn-r">
                     <div class="font-s24 font-weight p-b-10">{{issue}}</div>
                     <div class="font-s14 c66">{{hintTxt}}</div>
                 </div>
            </div>
            <!-- 按钮 -->
            <div class="account-footer clearfix">
                <div><el-button @click="handleClose">取 消</el-button></div>
                <div><el-button type="primary" @click="confirm">确 定</el-button></div>
            </div>
        </el-dialog>
        <!-- 操作性按钮弹框 end-->
    </div>
</template>

<script>
export default {
  data() {
    return {
      operationBtn: true, //操作性按钮显示状态
      dataCode: 0 //是否调用其他事件   0不调  1调用
    };
  },
  props: {
    issue: "", //问题   上面的文字
    hintTxt: "", //提示文本   下方的文字
    showHide: Boolean, //弹框的隐藏显示
    operationId: "", //操作id
    operationPsotUrl: "", //接口名字
    operationPsotName:'',//请求参数名字
    parameter:'',
  },
  created(){
    //   console.log(this.operationPsotName)
  },
  methods: {
    handleClose(done) {
      this.operationBtn = false;
      this.operationBtn = 0;
      /**
       * 参数说明
       * 1、事件名
       * 2、关闭后将状态返回给父组件
       * 3、是否调用其他事件   0不调  1调用
       *  */
      this.$emit("childByOperationBtn", this.operationBtn, this.dataCode);
    },
    confirm() {
        var postName=this.operationPsotName;
        var db = {};
        if(postName == undefined){
            var key = postName;    
            db[key] = this.operationId;
        }else{
            // db = this.parameter;
            var key = postName;    
            db[key] = this.operationId;
        }
        this.$axios.post(this.operationPsotUrl, db).then(
        res => {
          if (res.data.code == 1) {
            this.dataCode = 1;
          } else {
            this.dataCode = 0;
            this.$message.error(res.data.msg);
          }
          this.operationBtn = false;
          this.$emit("childByOperationBtn", this.operationBtn, this.dataCode);
        },
        err => {
          this.$message.error(err.msg);
        }
      );
    }
  }
};
</script>

<style>
/*
调用实例
HTML
<el-button type="text" @click.stop="operation(2,'同意')">同意</el-button>
<el-button type="text" @click.stop="operation(2,'拒绝')">拒绝</el-button>

调用组件
<operationBtn  v-if="showHide"  :issue='issue' :hintTxt='hintTxt' :showHide="showHide" :operationId='operationId'  :operationPsotUrl='operationPsotUrl' @childByOperationBtn="childByOperationBtn" :operationPsotName="operationPsotName"></operationBtn>

<script>
import operationBtn from "../../components/operationBtn";
import { DelAccountUser } from "../../api/api";
export default {
  components: {
    operationBtn,
  },
  data() {
    return {
        // 操作性按钮弹框值   start
            issue:'',//问题   上面的文字
            hintTxt:'', //提示文本   下方的文字
            showHide:false, //弹框的隐藏显示
            operationId:'', //操作id
            operationPsotUrl:'', //操作接口名字
            operationPsotName:'',//请求参数
        // 操作性按钮弹框值   end
    };
  },
  methods: {
    operation(operationId,operationName){ //对数据的操作-按钮   operationName-按钮名称    operationId-操作id
       this.operationPsotName='';
      if(operationName=='同意'){
          //   修改组件提示文本
         this.issue='确定同意该转诊申请？';
         this.hintTxt='同意该患者按约定时间来院挂号';
         this.operationPsotUrl=DelAccountUser; //修改即将调用的接口名字
      }  
      else{ //拒绝
           this.issue='确定拒绝该转诊申请？';
           this.hintTxt='同意该患者按约定时间来院挂号';
           this.operationPsotUrl=DelAccountUser; //修改即将调用的接口名字
      } 
      this.operationId=operationId; //修改组件id
      this.showHide=true; //显示组件
    },
     childByOperationBtn: function (showHide,dataCode) {
       this.showHide=showHide;
       if(dataCode==1){ //接口调用成功，调用其他接口
       }
      },
  }
};
</script>

*/
</style>

